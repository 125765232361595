// Entry point for public category pages.

import SignInModal from 'components/authentication_modals/SignInModal'
import SignUpModal from 'components/authentication_modals/SignUpModal'
import UnauthedSupplierSearch from 'components/search/UnauthedSupplierSearch'

import ReactRailsUJS from 'react_ujs'

const components = {
  'authentication_modals/SignInModal': SignInModal,
  'authentication_modals/SignUpModal': SignUpModal,
  'search/UnauthedSupplierSearch': UnauthedSupplierSearch
}

function componentLoadPathContext (fileToImport) {
  return components[fileToImport.replace(/^\.\//, '')]
}

ReactRailsUJS.useContext(componentLoadPathContext)

// Fixes foundation not being initialized when following a deep link when unauthorized
// Note: might only occur on Safari browsers
// this is not a perfect fix, as it triggers warnings in the console, but that's better
// than console errors and the modal not working.
// The correct, long term fix is moving off of foundation.
$('#sign-up-modal').foundation()
$('#sign-in-modal').foundation()
